body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Leaflet CSS styles. */
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-toolbar/dist/leaflet.toolbar.css";
@import "~leaflet-distortableimage/dist/leaflet.distortableimage.css";

.leaflet-popup-content-wrapper {
    border-radius: 6px;
}

.leaflet-popup-content {
    margin: 0px;
}

.map-popup-dark {
    & a.leaflet-popup-close-button {
        color: #b0b0b0;
    }

    & a:hover.leaflet-popup-close-button {
        color: white;
    }

    & .leaflet-popup-content-wrapper,
    & .leaflet-popup-tip {
        background-color: rgb(42, 42, 42);
    }
}

.map-popup-light {
    & .leaflet-popup-content-wrapper,
    & .leaflet-popup-tip {
        background-color: #f0f0f0;
    }
}
